import aboutCardIcon1 from "../../images/icon/aboutus_card_icon1.png"
import aboutCardIcon2 from "../../images/icon/aboutus_card_icon2.png"
import aboutCardIcon3 from "../../images/icon/aboutus_card_icon3.png"

 const data = {
    aboutDescription1: 'Famed Italian 🇮🇹 Artist Rino Russo (170K followers on instagram and posts shared by Snoop Dogg, Steve Aoki, Kevin Hart, Neymar etc.) has just announced his first major NFT collection, Springfield Apes! These are not your typical static Profile Pics collection, they are truly Dynamic NFTs, HODLing will be rewarded 🤫',
    aboutDescription2: 'The year is 2025, and following a nuclear disaster at the Springfield Nuclear Power Plant, thousands of apes enter the plant to take care of the clean up. Days later, the Apes emerge from  the plant completely transformed into Humanoid Chimps. Immune to radiation, they now inhabit the abandoned town of Springfield.',
    aboutInfo: [
        {
            icon: aboutCardIcon1,
            title: "Iconic artist-led NFT",
            text: "Our NFTs feature a unique traits hand-drawn by Rino Russo, one of the most iconic artists in the NFT space 😁. Springfield Apes draws inspiration from the Simpsons, the most iconic cartoon of all time and Bored Ape Yacht Club, the most iconic NFT project of all time."
        },
        {
            icon: aboutCardIcon2,
            title: "Dynamic NFTs",
            text: 'Springfield Apes are not another static profile pic NFT collection. They are true dynamic NFTs, that evolve over time based on certain factors. So in reality holding Springfield Ape you are getting many unique pieces of art that will reveal over time, not a single boring static image.'
        },
        {
            icon: aboutCardIcon3,
            title: "Rino's Art, Merch and Future Drops",
            text: 'Springfield Apes holders will be have exclusive access to limited edition merch, an opportunity at receiving a Simpsonized family portrait drawn by Rino, and access unique art pieces by Rino directly from the Springfield Apes website.'
        }
    ]
}

export default data;