import SectionTitle from "../../../../common/sectionTitle";

import data from "../../../../assets/data/about/aboutv2";
import aboutThumb1 from "../../../../assets/images/nft/about_us2_img1.jpg";
import AboutStyleWrapper from "./About.style";

const AboutTwo = () => {
  const { aboutDescription1, aboutDescription2, aboutDescription3, aboutDescription4 } = data;
  return (
    <AboutStyleWrapper id="artist">
      <div className="container">
        <div className="row flex-row-reverse">

          <div className="col-md-6">
            <div className="v1_about_us_left_sect sm-mt-60">
              <div className="v1_about_us_img_card v1_about_us_img_card1">
                <div className="v1_about_us_img v1_about_us_img1">
                  <img src={aboutThumb1} alt="img" />
                </div>
                <div className="v1_about_us_img1_shapes">
                  <div className="v1_about_us_img1_shapes_left">
                    <div className="shape_1"></div>
                    <div className="shape_2"></div>
                  </div>
                  <div className="v1_about_us_img1_shapes_right">
                    <div className="shape_3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="v1_about_us_right_sect">
              <SectionTitle title="RINO RUSSO" subtitle="ABOUT" />
              <div className="v1_about_us_right_text">
                <p>{aboutDescription1}</p>
                <p>{aboutDescription2}</p>
                <p>{aboutDescription3}</p>
                <p>{aboutDescription4}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AboutStyleWrapper>
  );
};

export default AboutTwo;
