import BannerV1Wrapper from "./Banner.style";


import Button from "../../../../common/button";


const Banner = () => {

  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="bithu_v1_baner_left">
              <div className="banner_buttons">
              <a href="https://mint.springfieldapes.com/" target="_blank" rel="noreferrer">
              <Button lg variant="mint">
                    Mint Page
              </Button> 
              </a>
              </div>
              <div className="coin-info">
                <span>0.03 WL, Max 3 NFT per wallet<br/>0.04 Public, Max 3 NFT per wallet</span>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="bithu_v1_baner_right">
              <div className="bithu_v1_baner_right_img_sect">
                <div className="bithu_v1_baner_right_img">
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
