const data = [
  {
    title: "Is Springfield Apes associated with the Simpsons?",
    text: "No, Springfield Apes is in no way associated with the Simpsons. Springfield Apes are unique artwork, drawing inspiration from both the Simpsons and Bored Ape Yacht Club, in combination with our artist, Rino Russo’s unique art style.",
  },
  {
    title: "Does the artist Rino Russo have a connection to the Simpsons?",
    text: "Rino does not have a direct connection to the Simpsons. However, Rino has become extremely well-known for Simposonizing celebrities on his instagram, including the likes of Snoop Dogg, Neymar, Kevin Hart, Michael Phelps etc. With these celebs kindly resharing Rino’s artwork on their own instagrams!",
  },
  {
    title: "What is the Springfield Apes mint date?",
    text: "The mint date is Tuesday December 20th at 10am EST.",
  },
  {
    title: "What will be the Springfield Apes mint price?",
    text: "0.03ETH on WL with max 3 NFTs per wallet. And 0.04ETH on Public Sale with max 3 NFTs per wallet.",
  },
  {
    title: "What will be the Springfield Apes supply?",
    text: "Springfield Apes will be a 5,000 NFT collection on Ethereum",
  },
  {
    title: "Does Springfield Apes have an official Twitter?",
    text: "Yes, our official Twitter is @SprinfieldApes",
  },
  {
    title: "What are Rino Russo’s official Twitter and Instagram?",
    text: "Rino’s official Twitter is @RinaldoRu and official Instagram is @rino_russo_",
  },
  {
    title: "Does Springfield Apes have an official Discord?",
    text: "There is currently no official Springfield Apes discord. When one is created we will announce its creation on twitter, check our official Twitter @SprinfieldApes for updates.",
  },
];

export default data;
