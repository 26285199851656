import contract from '../contracts/bithuabi.json';
import { ethers } from 'ethers';
import { isMetaMaskInstalled, ethereum } from '../config';



export const mint = async (mint_amount) => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contractAddress = "0x7797cfBaA488212CA5B943445dB24Ff466Ac517e";
        const nftContract = new ethers.Contract(contractAddress, contract, signer);
        let txnHash = await nftContract.makingobblin(mint_amount,{
            gasLimit: "285000",
            value: ethers.utils.parseEther((0.0 * mint_amount).toString())
        })
        return txnHash
    }
}

export const totalMintCount = async () => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contractAddress = "0x7797cfBaA488212CA5B943445dB24Ff466Ac517e";
        const nftContract = new ethers.Contract(contractAddress, contract, signer);

        let totalMint = await nftContract.goblins();

        return totalMint;
    }
}
