import characterThumb2 from "../../images/nft/carousel_avater2.jpeg";
import characterThumb3 from "../../images/nft/carousel_avater3.jpeg";
import characterThumb4 from "../../images/nft/carousel_avater4.jpeg";
import characterThumb5 from "../../images/nft/carousel_avater5.jpeg";
import characterThumb6 from "../../images/nft/carousel_avater6.jpeg";
import characterThumb7 from "../../images/nft/carousel_avater7.jpeg";
import characterThumb8 from "../../images/nft/carousel_avater8.jpeg";
import characterThumb9 from "../../images/nft/carousel_avater9.jpeg";
import characterThumb10 from "../../images/nft/carousel_avater10.jpeg";

const data = [
  {
    thumb: characterThumb2,
  },
  {
    thumb: characterThumb3,
  },
  {
    thumb: characterThumb4,
  },
  {
    thumb: characterThumb5,
  },
  {
    thumb: characterThumb6,
  },
  {
    thumb: characterThumb7,
  },
  {
    thumb: characterThumb8,
  },
  {
    thumb: characterThumb9,
  },
  {
    thumb: characterThumb10,
  },
];

export default data;
