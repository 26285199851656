export const footerLinksV1 = [
  {
    title: "Home",
    url: "https://www.springfieldapes.com",
  },
  {
    title: "About",
    url: "#about",
  },
  {
    title: "Artist",
    url: "#artist",
  },
  {
    title: "FAQ",
    url: "#faq",
  },
];
