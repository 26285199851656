import { FaTwitter } from "react-icons/fa";
import { BsXLg } from "react-icons/bs";
import logo from "../../../../assets/images/logo.png";
import MobileMenuStyleWrapper from "./MobileMenu.style";

const MobileMenu = ({ mobileMenuhandle }) => {


  return (
    <MobileMenuStyleWrapper className="bithu_mobile_menu">
      <div className="bithu_mobile_menu_content">
        <div className="mobile_menu_logo">
          <img className="bithu_logo" src={logo} alt="bithu logo" />
          <button
            className="mobile_menu_close_btn"
            onClick={() => mobileMenuhandle()}
          >
            {" "}
            <BsXLg />{" "}
          </button>
        </div>
        <div className="bithu_mobile_menu_list">
          <ul>
          <li className="mobile_menu_hide">
              <a href="/">Home</a>
            </li>
            <li className="mobile_menu_hide">
              <a href="#about">About</a>
            </li>
            <li className="mobile_menu_hide">
              <a href="#artist">Artist</a>
            </li>
            <li className="mobile_menu_hide">
              <a href="#faq">FAQ</a>
            </li>
          </ul>
        </div>
        <div className="mobile_menu_social_links">
          <a href="https://twitter.com/SpringfieldApes">
            <FaTwitter />
          </a>
        </div>
      </div>
    </MobileMenuStyleWrapper>
  );
};

export default MobileMenu;
